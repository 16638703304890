import { Layout } from "../../layouts";
import { Title, Section, Button, FormInput } from "@lleed-partners/factor";
import { Download32 } from "@carbon/icons-react";
import { Icon } from "../../fragments/Icon";
import { StaticImage } from "gatsby-plugin-image";
import { asBackground } from "../../fragments/styles";
import { I18nextContext, useI18next, Link } from "gatsby-plugin-react-i18next";
import { Helmet } from "gatsby-plugin-react-i18next";
import { useContext } from "react";
import { graphql } from "gatsby";

const sheetStyles = {
  borderRadius: "5px",
  overflow: "hidden",
  boxShadow: "0 5px 15px rgba(0, 0, 0, .1), 0 1px 2px 1px rgba(0, 0, 0, .1)",
};

export default () => {
  const { t } = useI18next();

  const { language } = useContext(I18nextContext);

  return (
    <Layout>
      <Helmet>
        <meta
          name="description"
          content={t(
            "Discover how we leveraged teams' expertise along with next generation technologies to automate metals sales processes, increasing efficiency and ensuring compliance."
          )}
        />
        <meta
          property="og:image"
          content="https://images.unsplash.com/photo-1570979872224-a1ea9f1248b0"
        />
        <title>{t("Automating metals sales processes")}</title>
      </Helmet>
      <Section
        style={{
          paddingTop: "15rem",
        }}
        fullScreen
        intent="ghostInverted"
        backgroundMedia={
          <StaticImage
            src="https://images.unsplash.com/photo-1570979872224-a1ea9f1248b0"
            alt=""
            style={asBackground}
            loading="eager"
          />
        }
        title={
          <Title
            inline={false}
            title={t("Automating metals sales processes:")}
            subtitle={t("reducing friction and increasing efficiency.")}
          >
            {t(
              "Discover how we leveraged teams' expertise along with next generation technologies to automate metals sales processes, increasing efficiency and ensuring compliance."
            )}
          </Title>
        }
      ></Section>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          flexDirection: "row-reverse",
          alignItems: "center",
        }}
      >
        <div
          style={{
            padding: "2rem",
            flex: "1 0 20rem",
          }}
        >
          <div
            style={{
              maxWidth: "25rem",
              margin: "auto",
            }}
          >
            <div
              style={{
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "0",
                  right: "0",
                  paddingBottom: "4rem",
                  paddingRight: "4rem",
                }}
              >
                <StaticImage
                  style={sheetStyles}
                  src="../../images/content/marketing/brochure-thumbnails/case-study-aluminum-2.jpg"
                  alt="Brochure"
                />
              </div>
              <div
                style={{
                  paddingTop: "4rem",
                  paddingLeft: "4rem",
                }}
              >
                <StaticImage
                  style={sheetStyles}
                  src="../../images/content/marketing/brochure-thumbnails/case-study-aluminum-1.jpg"
                  alt="Brochure"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            padding: "2rem",
            flex: "1 0 20rem",
          }}
        >
          <Title
            inline={false}
            title={t("Learn how you can automate your processes,")}
            subtitle={t("leveraging your own expertise.")}
            cta={
              <div style={{ maxWidth: "25rem" }}>
                <form
                  method="post"
                  netlify-honeypot="bot-field"
                  data-netlify="true"
                  name="metals-sales"
                  action={"/" + language + "/brochures/metals-sales-download/"}
                >
                  <input type="hidden" name="bot-field" />
                  <input type="hidden" name="form-name" value="metals-sales" />
                  <FormInput
                    type="email"
                    label={t("Enter your work email")}
                    placeholder="john.doe@company.com"
                    name="email"
                    required={true}
                  />
                  <div style={{ display: "flex" }}>
                    <Button
                      intent="primary"
                      rightIcon={<Icon icon={<Download32 />} />}
                      as="button"
                      type="submit"
                    >
                      Download
                    </Button>
                    <p style={{ paddingLeft: "1rem" }}>
                      or{" "}
                      <Link to="/brochures/metals-sales-download/">
                        download
                      </Link>{" "}
                      without registering.
                    </p>
                  </div>
                </form>
              </div>
            }
          >
            {t(
              "The company is a multinational mining group, particularly involved in aluminum. As of today, the firm has more than 40,000 employees worldwide. We collaborate with their freight, aluminum and copper trading teams around the world to design and developed a tailor-made web solution in collaboration with the firm's trading and digital teams."
            )}
          </Title>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
